@import "tailwindcss/components";

@layer components {
  .to-gray-btn {
    @apply flex items-center;
    @apply transition-colors duration-150;
    @apply py-3 px-6;
    @apply rounded-lg;
    @apply text-sm font-medium;
    @apply hover:bg-gray-100;
  }

  .to-gray-btn.active {
    @apply bg-gray-100;
    @apply hover:bg-gray-200;
  }

  .classic-btn {
    @apply inline-flex items-center;
    @apply rounded-md;
    @apply border border-gray-300;
    @apply shadow-sm;
    @apply px-4 py-2;
    @apply bg-white;
    @apply text-base font-medium text-gray-700;
    @apply hover:bg-gray-50 focus:outline-none;
    @apply sm:text-sm;
  }

  .classic-btn.red {
    @apply text-red-500;
    @apply border-red-300;
  }
}
