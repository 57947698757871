@import "tailwindcss/components";

@layer components {
  table.table {
    @apply min-w-full;
    @apply divide-y divide-gray-200;
  }

  table.table thead {
    @apply bg-gray-50;
  }

  table.table thead tr th {
    @apply px-6 py-3;
    @apply text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
  }

  table.table tbody {
    @apply bg-white;
    @apply divide-y divide-gray-200;
  }

  table.table tbody tr td {
    @apply py-4 px-6;
    @apply whitespace-nowrap;
  }
}
