@import "tailwindcss/components";

@layer components {
  div#sign-in-container {
    @apply container;
    @apply min-h-full;
    @apply flex items-center justify-center;
    @apply py-12 px-4 sm:px-6 lg:px-8;
  }

  div#sign-in-container div#sign-in-wrapper {
    @apply max-w-md w-full space-y-8;
  }

  div#sign-in-container form {
    @apply mt-8 space-y-6;
  }

  div#sign-in-container form input.sign-in-field {
    @apply appearance-none;
    @apply relative block w-full;
    @apply rounded-sm;
    @apply px-3 py-2 my-1.5;
    @apply border border-gray-300;
    @apply placeholder-gray-500 text-gray-900;
    @apply focus:outline-none focus:ring-ebony focus:border-ebony focus:z-10;
    @apply sm:text-sm;
  }

  div#sign-in-container form input.sign-in-field.has-error {
 /*    @apply border-red-500;
    @apply placeholder-red-500 text-red-900;
    @apply focus:ring-red-500 focus:border-red-500; */
  }

  div#sign-in-container form div#sign-in-form-wrapper {
    @apply space-y-2;
  }
}
