@import "tailwindcss/components";

@layer components {
  div#layout main {
    @apply flex flex-col flex-grow;
    @apply overflow-hidden;
  }

  div#layout div#user-control {
    @apply p-4;
    @apply border-b border-gray-200;
  }

  div#layout div#user-control div#user-control-wrapper {
    @apply flex items-center;
    @apply h-12;
  }

  div#layout div#user-control div#user-control-wrapper div#user {
    @apply text-sm;
    @apply ml-auto;
  }

  div#layout div#content {
    @apply relative overflow-auto flex-grow;
  }

  div#layout div#content-without-overflow {
    @apply relative overflow-hidden flex-grow;
  }

  .header {
    @apply flex items-center;
    @apply w-full;
    @apply p-4;
    @apply bg-white;
  }

  .header-top {
    @apply header;
    @apply border-b border-gray-200;
  }

  .header-bottom {
    @apply header;
    @apply border-t border-gray-200;
  }
}
