@import "tailwindcss/components";

@layer components {
  form.action {
    @apply p-6;
  }

  form.action div.wrapper {
    @apply grid grid-cols-6;
    @apply gap-4;
  }

  form.action div.wrapper div.item {
    @apply col-span-6 xl:col-span-3 space-y-4;
  }

  form.action div.wrapper div.item.fluid {
    @apply col-span-6;
  }

  form.action div.wrapper div.item label {
    @apply block;
    @apply text-sm font-medium text-gray-700;
  }

  form.action div.wrapper div.item span.error {
    @apply text-sm text-red-500;
  }

  form textarea,
  form input[type="text"],
  form input[type="password"],
  form input[type="number"] {
    @apply block w-full;
    @apply shadow-sm;
    @apply sm:text-sm;
    @apply border-gray-300;
    @apply rounded-md;
    @apply focus:ring-ebony focus:border-ebony;
  }

  form textarea.has-error,
  form input[type="text"].has-error,
  form input[type="password"].has-error,
  form input[type="number"].has-error {
    /* @apply border-red-500;
    @apply focus:ring-red-500 focus:border-red-500; */
  }

}
