@import "tailwindcss/base";
@import "tailwindcss/utilities";

@layer base {
  noscript{
    @apply w-full h-screen;
    @apply flex items-center justify-center;
  }

  html,
  body {
    @apply w-full h-screen;
  }

  div#root {
    @apply flex w-full h-full items-center justify-center antialiased;
  }

  div#root div#layout {
    @apply w-full h-full;
    @apply flex;
  }
}
