@import "tailwindcss/components";

@layer components {
  .modal {
    @apply fixed inset-0;
    z-index: 70;
    @apply overflow-y-auto;
  }

  .modal .modal-wrapper {
    @apply flex items-end justify-center;
    @apply min-h-screen;
    @apply py-4 px-4;
    @apply text-center;
    @apply sm:block sm:p-0;
  }

  .modal .modal-overlay {
    @apply fixed inset-0;
    @apply bg-gray-500 bg-opacity-75;
    @apply transition-opacity;
  }

  .modal .modal-content {
    @apply inline-block align-bottom;
    @apply bg-white;
    @apply rounded-lg;
    @apply text-left;
    @apply overflow-hidden;
    @apply shadow-xl;
    @apply transform transition-all;
    @apply sm:my-8 sm:align-middle sm:max-w-lg sm:w-full;
  }

  .modal .modal-content .modal-actions {
    @apply bg-gray-50;
    @apply px-4 py-3;
    @apply sm:px-6 sm:flex sm:flex-row-reverse;
  }
}
