@import "tailwindcss/components";

@layer components {
  .listbox-btn {
    @apply relative w-full;
    @apply py-2 pl-3 pr-10;
    @apply text-left;
    @apply bg-white;
    @apply rounded-lg;
    @apply cursor-pointer;
    @apply border border-gray-300;
    @apply shadow-sm;
    @apply focus:outline-none;
    @apply focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2;
    @apply focus-visible:border-indigo-500;
    @apply sm:text-sm;
  }

  .listbox-btn .listbox-title {
    @apply block truncate;
  }

  .listbox-btn .listbox-selector-icon {
    @apply absolute inset-y-0 right-0;
    @apply flex items-center;
    @apply pr-2;
    @apply pointer-events-none;
  }

  .listbox-options {
    @apply absolute;
    @apply z-10;
    @apply w-full max-h-60;
    @apply py-1 mt-1;
    @apply overflow-auto;
    @apply text-base;
    @apply bg-white;
    @apply rounded-md;
    @apply shadow-lg;
    @apply ring-1 ring-black ring-opacity-5;
    @apply focus:outline-none sm:text-sm;
  }
}
