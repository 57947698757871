@import "tailwindcss/components";

@layer components {
  div#sidebar {
    @apply xl:flex flex-col flex-shrink-0;
    @apply absolute inset-0 xl:relative;
    @apply w-full md:w-[280px] h-full;
    @apply bg-ebony text-white;
    z-index: 60;
    @apply xl:border-r-4 xl:border-ebony-light;
  }

  div#sidebar div#sidebar-logo {
    @apply flex items-center justify-between;
    @apply p-4;
    @apply border-b border-ebony-light;
  }

  div#sidebar nav#menu {
    @apply flex flex-col;
    @apply pt-4 px-4;
    @apply space-y-2;
    @apply overflow-y-auto;
  }

  div#sidebar nav#menu a.menu-item {
    @apply flex items-center;
    @apply transition-colors duration-150;
    @apply py-3 px-6;
    @apply rounded-lg;
    @apply w-full;
    @apply text-sm font-medium;
    @apply hover:bg-white hover:bg-opacity-5;
  }

  div#sidebar nav#menu a.menu-item svg {
    @apply h-5 w-5;
    @apply mr-2;
  }

  div#sidebar nav#menu a.menu-item.active,
  div#sidebar nav#menu a.menu-item[aria-current="page"] {
    @apply bg-white bg-opacity-5;
    @apply text-indigo-200;
  }

  div#sidebar div#sign-out {
    @apply mt-auto;
    @apply p-4;
  }

  div#sidebar div#sign-out button {
    @apply flex items-center;
    @apply transition-colors duration-150;
    @apply bg-white bg-opacity-5;
    @apply py-3 px-6;
    @apply rounded-lg;
    @apply w-full;
    @apply text-sm font-medium;
  }
}
