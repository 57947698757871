@import "tailwindcss/components";

@import "components/buttons.css";
@import "components/modal.css";
@import "components/table.css";
@import "components/form.css";
@import "components/listbox.css";

@layer components {
  .absolute-page {
    @apply absolute inset-0;
    @apply flex items-center justify-center;
    @apply w-full h-full;
  }

  .absolute-white-page {
    @apply bg-white;
    @apply absolute-page;
  }

  div.loading {
    @apply absolute-white-page;
    @apply z-50;
  }

  div.network-error {
    @apply absolute-white-page;
    @apply z-40;
  }
}
